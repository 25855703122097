import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PwaContext from "../../utils/pwaContext";
import { Logo } from "../Logo";
import { wait } from "../../utils/wait";
import { PiVideoFill } from "react-icons/pi";
import { RiMenu3Fill, RiShareFill } from "react-icons/ri";
import { MdHeadsetMic, MdInstallMobile, MdPhoneInTalk } from "react-icons/md";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Text,
  useToast,
  Divider,
  IconButton,
  Tooltip,
  background,
} from "@chakra-ui/react";

const MenuBar = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { appinstalled, enabledPwa } = useContext(PwaContext);
  const toast = useToast();
  const [sharingWebsite, setSharingWebsiteState] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Button
        display={{
          base: "flex",
          lg: "none",
        }}
        variant="ghost"
        leftIcon={<RiMenu3Fill />}
        onClick={onOpen}
      >
        منو
      </Button>

      <Tooltip
        display={{
          base: "none",
          lg: "flex",
        }}
        label="منو"
        hasArrow
      >
        <IconButton
          display={{
            base: "none",
            lg: "flex",
          }}
          aria-label="Menu Button"
          variant="ghost"
          icon={<RiMenu3Fill />}
          onClick={onOpen}
        />
      </Tooltip>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent maxWidth="85%">
          <DrawerHeader
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <DrawerCloseButton />
            <Logo width="64px" />
          </DrawerHeader>
          <DrawerBody padding={0}>
            {props.menuItem.map((item, index) => {
              return (
                <Button
                  key={`HeaderMenuBarItem-${index}`}
                  width="100%"
                  leftIcon={item.icon}
                  size="lg"
                  justifyContent="flex-start"
                  variant="ghost"
                  onClick={() => {
                    onClose();
                    if (item.link?.startsWith("http")) {
                      return window.open(item.link, "_blank");
                    }
                    wait(111).then(() => navigate(item.link));
                  }}
                >
                  {item.text}
                </Button>
              );
            })}

            <Divider
              h={2}
              borderStyle={"dashed"}
              borderBottomWidth={"2px"}
              pt={2}
              pb={1}
            />

            <Button
              key="HeaderMenuBarItem-ShareApplication"
              width="100%"
              leftIcon={<RiShareFill />}
              size="lg"
              justifyContent="flex-start"
              variant="ghost"
              isLoading={sharingWebsite}
              onClick={async () => {
                setSharingWebsiteState(true);

                try {
                  if (window.navigator.share) {
                    await window.navigator.share({
                      title: "اشتراک گذاری وبسایت خادمان",
                      text: `🌺 سلام دوست من

☺️ شما که دلـــــ❤️ـــــت امام زمانیه چرا توی جمع ما نیستی ؟
                     
🌱چند وقتی هست عضو کانال خادمان امـام زمان هســـتم، یک‌ خـــانواده بــزرگ مهدوی تشکیل دادیم و هر روز صبح بین الطلوعین از حرم امام رضـا عــلیه‌السلام با آقــا امام زمان عــــجل الله فـرجه دست جمعی عهد میبندیم که زمینه ساز ظهور آقا باشیم

🙏 یک سری بزن حتما خوشت میاد این آدرس کانال‌هاشون 

پیام رسان ایتا 👇
https://eitaa.com/khademan_emamezaman
پیام رسان روبیکا 👇
https://rubika.ir/khademan_emamezaman

این هم آدرس سایتشونه👇
https://shamim313.com`,
                      // files: [file],
                    });
                  } else {
                    throw new Error("The sharing not support on this browser!");
                  }
                } catch (_) {
                  toast({
                    title: "فرآیند اشتراک گذاری تکمیل نشد!",
                    description:
                      "ممکن است مرورگر شما امکان اشتراک گذاری را  پشتیبانی نکند. میتوانید از آخرین نسخه کروم Chrome استفاده کنید.",
                    status: "warning",
                    duration: 6000,
                    isClosable: true,
                  });
                }

                onClose();
                setSharingWebsiteState(false);
              }}
            >
              دعوت از دوســتـــان
            </Button>

            <Button
              key="HeaderMenuBarItem-Support"
              width="100%"
              leftIcon={<MdHeadsetMic />}
              size="lg"
              justifyContent="flex-start"
              variant="ghost"
              onClick={() => {
                onClose();

                /** Checks if the Goftino service is available */
                if (window.GoftinoService) {
                  /** Opens the Goftino message sending panel */
                  window.GoftinoService.open();
                }
              }}
            >
              پشتـیبــانی سامـانه
            </Button>

            <Button
              key="HeaderMenuBarItem-ContactSupport"
              width="100%"
              leftIcon={<MdPhoneInTalk />}
              size="lg"
              justifyContent="flex-start"
              variant="ghost"
              onClick={() => {
                onClose();
                wait(111).then(() => navigate("/contact"));
              }}
            >
              راههای‌ ارتـبـاط‌ با ما
            </Button>

            {!appinstalled ? (
              <Button
                key="HeaderMenuBarItem-InstallApplication"
                width="100%"
                leftIcon={<MdInstallMobile />}
                size="lg"
                justifyContent="flex-start"
                variant="ghost"
                onClick={() => {
                  onClose();

                  if (!enabledPwa) {
                    toast({
                      title: "امکان نصب برنامه روی این مرورگر وجود ندارد!",
                      status: "error",
                      duration: 6000,
                    });
                  } else {
                    const event = window.AppInstallEvent;
                    if (event) {
                      // Display install modal
                      event.prompt();
                      // event.userChoice.then((choiceResult) => {
                      //   if (choiceResult.outcome === "accepted") {}
                      // });
                    } else {
                      toast({
                        title: "امکان نصب برنامه روی این مرورگر وجود ندارد!",
                        status: "error",
                        duration: 6000,
                      });
                    }
                  }
                }}
              >
                نـصـب اپـلیـکــیـشن
              </Button>
            ) : null}

            <Button
              key="HeaderMenuBarItem-Video"
              width="100%"
              leftIcon={<PiVideoFill />}
              size="lg"
              justifyContent="flex-start"
              variant="ghost"
              onClick={() => {
                // onClose();
                // wait(111).then(() => navigate("/contact"));
              }}
            >
              آموزش ویدئویی سامانه
            </Button>
          </DrawerBody>

          <DrawerFooter>
            {/* //TODO Versioning */}
            <Text>ورژن 3.2</Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MenuBar;
