import { useAuth } from "hooks/useAuthContext";
import { RiCheckFill } from "react-icons/ri";
import Cookies from "js-cookie";
import { useMemo, useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import IranCities from "../../assets/library/iranCities";
import IranProvinces from "../../assets/library/iranProvinces";
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

export const HIDE_REGISTER_ADDRESS_ALERT_KEY = "HIDE_REGISTER_ADDRESS_ALERT";

export default function RegisterAddressAlert() {
  const {
    BASE_URL,
    token,
    sid,
    isAuthenticated,
    checkForInvalidToken,
    getUserInfo,
  } = useAuth();
  const profile = getUserInfo();
  const toast = useToast();
  const [userProvince, setUserProvince] = useState("");
  const [userCity, setUserCity] = useState("");
  const [hideAlert, setHideAlert] = useState(
    Cookies.get(HIDE_REGISTER_ADDRESS_ALERT_KEY) === "true"
  );

  const updateUserAddressMutation = useMutation({
    mutationKey: ["updateUserAddressMutation"],
    retry: 0,
    mutationFn: async () => {
      await axios.put(
        `${BASE_URL}/profile/`,
        {
          city: userCity,
          province: userProvince,
          social_media: profile?.social_media ?? null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Sid: sid,
          },
        }
      );
    },
    onSuccess: () => {
      toast({
        title: "آدرس شما با موفقیت ثبت شد",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      Cookies.set(HIDE_REGISTER_ADDRESS_ALERT_KEY, "true");
      setHideAlert(true);
    },
    onError: (e) => {
      if (checkForInvalidToken(e)) return;

      toast({
        title: "مشکلی هنگام ثبت تغییرات پیش آمده است!",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    },
  });

  const cityOptions = useMemo(() => {
    if (userProvince.length > 0) {
      return IranCities[userProvince] || [];
    }

    return [];
  }, [userProvince]);

  if (!isAuthenticated || hideAlert || (profile?.city && profile.province)) {
    return <></>;
  }

  return (
    <Card className="rising_card" bgColor="#ffd000">
      <CardBody as={VStack} spacing=".5rem" alignItems="stretch">
        <Heading size="sm">اطلاعات آدرس شما ناقص است!</Heading>

        <Text textAlign="start" fontWeight="bold" fontSize="sm" opacity=".54">
          جهت بهرمندی از تمامی امکانات ارائه شده در اپلیکیشن، لطفاً شهر و استان
          خود را در فیلدهای زیر وارد کنید.
        </Text>

        <HStack spacing=".75rem">
          <FormControl>
            <FormLabel>استان</FormLabel>

            <Select
              value={userProvince}
              placeholder="هیچکدام"
              bgColor="var(--c--background)"
              onChange={(event) => {
                setUserCity("");
                setUserProvince(event.target.value);
              }}
            >
              {IranProvinces.map((p, index) => (
                <option key={`ProvinceOption_${index}`} value={p.value}>
                  {p.label}
                </option>
              ))}
            </Select>

            <FormErrorMessage>استان الزامی است!</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel>شهر</FormLabel>

            <Select
              value={userCity}
              placeholder="هیچکدام"
              bgColor="var(--c--background)"
              disabled={cityOptions.length === 0}
              onChange={(event) => setUserCity(event.target.value)}
            >
              {cityOptions.map((p, index) => (
                <option key={`CityOption_${index}`} value={p.value}>
                  {p.label}
                </option>
              ))}
            </Select>

            <FormErrorMessage>شهر الزامی است!</FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack justifyContent="flex-end" mt=".5rem">
          <Button
            size="sm"
            variant="text"
            onClick={() => {
              Cookies.set(HIDE_REGISTER_ADDRESS_ALERT_KEY, "true");
              setHideAlert(true);
            }}
          >
            دیگر نمایش نده!
          </Button>

          <Button
            size="sm"
            bgColor="white"
            _hover={{ bgColor: "white" }}
            leftIcon={<RiCheckFill />}
            isDisabled={userCity.length === 0 || userProvince.length === 0}
            isLoading={updateUserAddressMutation.isLoading}
            onClick={() => updateUserAddressMutation.mutate()}
          >
            ثبت آدرس
          </Button>
        </HStack>
      </CardBody>
    </Card>
  );
}
