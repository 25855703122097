import { Flex, Badge, Box, HStack, Spinner, Skeleton } from "@chakra-ui/react";
import { EventTimeInfo } from "hooks/useEventStore";
import { useEffect } from "react";

interface Participants {
  text: string;
  count: number;
  color: string;
  hasBadge?: boolean;
}

interface CountEventHeaderProps {
  participants: Participants;
  isLoading: boolean;
  isFinish: boolean;
}

export default function CountEventHeader({
  participants,
  isLoading,
  isFinish,
}: CountEventHeaderProps) {
  return (
    <Box margin="10px 0" h="28px" minW="96px">
      {participants !== null ? (
        isLoading ? (
          <Skeleton h="100%" w="100%" />
        ) : (
          <Badge
            p=".2rem .5rem"
            fontSize="sm"
            borderRadius={4}
            colorScheme={participants.color}
            minW={"96px"}
            height={"28px"}
          >
            <HStack spacing=".5rem">
              {participants.hasBadge && (
                <Box
                  w=".63rem"
                  h=".63rem"
                  borderRadius="100%"
                  bg={participants.color}
                  animation={
                    isFinish
                      ? ""
                      : `pulse_sm_${participants.color}_anim 1s ease-out infinite`
                  }
                />
              )}
              <span>
                {participants.text}:{" "}
                {parseInt(`${participants.count}`).toLocaleString("fa")}
              </span>
            </HStack>
          </Badge>
        )
      ) : null}
    </Box>
  );
}
