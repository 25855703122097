import { useEffect, useState } from "react";
import ManualPayment from "components/ManualPayment/ManualPayment";
import { Button, Flex, Text, useToast, VStack } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useAuth } from "hooks/useAuthContext";
import { useMutation, useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { ApiResponse, Bill } from "types/apiTypes";
import useDefaultToast from "hooks/useDefaultToast";
import PageHeader from "components/PageHeader";

const ManualPayments = () => {
  const showToast = useDefaultToast();
  const location = useLocation();
  const { subject } = useParams();
  const { title, id } = location.state || {};
  const [getData, setGetData] = useState(false);
  const [billTitle, setBillTitle] = useState(title);
  const [bill_id, setBill_id] = useState(id);

  useEffect(() => {
    if (!title || !id) {
      setGetData(true);
    }
  }, [title, id]);

  useQuery({
    queryKey: ["getBillQuery"],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    enabled: getData,
    queryFn: async () => {
      const res = await authorizedAxios.get<ApiResponse<Bill>>(
        `/bill/?subject=` + subject
      );
      const resBill = res.data.data.items[0];
      resBill.title && setBillTitle(resBill.title);
      resBill._id && setBill_id(resBill._id);

      return resBill;
    },
    onError: (e: AxiosError) => {
      //maybe net error
      if (e.code === "ERR_NETWORK") {
        //ERR_NETWORK error
        return showToast({
          title: "در برقراری ارتباط با سرور خطایی روی داد!",
          description: "لطفا اتصال اینترنت خود را بررسی نمایید.",
          id: "ERR_NETWORK",
          status: "error",
        });
      }

      //unhandled error
      return showToast({
        title: "در دریافت پرداخت خطایی روی داد!",
        description:
          "باعرض پوزش در دریافت پرداخت خطایی روی داد از اینکه شکیبایی می کنید متشکریم.",
        id: "getBillQuery_UNHANDLED_ERROR",
        status: "error",
      });
    },
  });

  const emptyFormProps = {
    amount: {
      value: "",
      isInvalid: false,
    },
    name: {
      value: "",
      isInvalid: false,
    },
    cartNumber: {
      value: "",
      isInvalid: false,
    },
    referenceNumber: {
      value: "",
      isInvalid: false,
    },
    description: {
      value: "",
      isInvalid: false,
    },
  };

  const [formProps, setFormProps] = useState(emptyFormProps);
  const { BASE_URL, token, sid, authorizedAxios } = useAuth();
  const toast = useToast();

  const handleFormProps = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const { value } = e.target;
    setFormProps((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value,
        isInvalid: value.trim() === "",
      },
    }));
  };

  const saveManualOrder = useMutation({
    mutationKey: ["saveManualOrder"],
    retry: 0,
    mutationFn: async () => {
      const { amount, cartNumber, description, name, referenceNumber } =
        formProps;

      await axios.post(
        `${BASE_URL}/manual_order/`,
        {
          amount: +amount.value,
          card_number: cartNumber.value,
          description: description.value,
          card_holder: name.value,
          reference_number: referenceNumber.value,
          bill_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            sid: sid,
          },
        }
      );
    },
    onSuccess: () => {
      setFormProps(emptyFormProps);
      toast({
        title: "فیش با موفقیت ثبت شد",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (e: { response: any }) => {
      toast({
        title: `کد خطا: ${e.response?.status || "نامشخص"}`,
        description: JSON.stringify(e.response?.data, null, 2),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    },
  });

  const handleSaveOrder = () => {
    const { amount, name, cartNumber, referenceNumber } = formProps;
    const tempFormProps = { ...formProps };
    const fields = [amount, name, cartNumber, referenceNumber];
    const fieldNames = ["amount", "name", "cartNumber", "referenceNumber"];
    let hasEmptyField = false;

    fields.forEach((field, index) => {
      if (field.value.trim() === "") {
        tempFormProps[fieldNames[index]].isInvalid = true;
        hasEmptyField = true;
      }
    });

    if (tempFormProps.cartNumber.value.length !== 16) {
      tempFormProps.cartNumber.isInvalid = true;
      setFormProps(tempFormProps);
      toast({
        title: "شماره کارت باید 16 رقم باشد",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }

    if (hasEmptyField) {
      setFormProps(tempFormProps);
      toast({
        title: "لطفا فیلدهای خالی را پر کنید",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    saveManualOrder.mutate();
  };

  return (
    <VStack spacing={6} margin="20px 0 50px 0">
      <PageHeader drawerTitle="راهنمای پرداخت" title="">
        <Flex flexDirection="column" alignItems="stretch" gap="1rem">
          <Text textAlign="justify">
            اگر پرداخت را بصورت کارت به کارت انجام داده اید در اینجا میتوانید
            اطلاعات پرداخت را ثبت کنید.
          </Text>
        </Flex>
      </PageHeader>
      <VStack>
        <Text fontWeight="600">فرم ثبت دستی کارت به کارت</Text>
        <Text fontWeight="900">جهت {billTitle}</Text>
      </VStack>
      <ManualPayment formProps={formProps} handleFormProps={handleFormProps} />
      <Button
        colorScheme="telegram"
        width={{ base: "100%", md: "auto" }}
        onClick={handleSaveOrder}
      >
        ثبت مشخصات پرداخت
      </Button>
    </VStack>
  );
};

export default ManualPayments;
