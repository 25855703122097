import styles from "../../assets/css/commentsListStyle.module.css";
import { useInfiniteQuery } from "react-query";
import { useMemo } from "react";
import axios from "axios";
import { useAuth } from "hooks/useAuthContext";
import { BiMessageAdd } from "react-icons/bi";
import CommentInfoDrawer from "../CommentInfoDrawer";
import CommentItem, { CommentSkeleton } from "../CommentItem";
import { RiDiscussFill, RiDownloadCloud2Line } from "react-icons/ri";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

export default function CommentsList({
  eventId = undefined,
  mainVStackProps = {},
}) {
  const { BASE_URL, token, sid, checkForInvalidToken, isAuthenticated } =
    useAuth();
  const registerNewCommentDrawerDisclosure = useDisclosure();

  const getCommentsInfiniteQuery = useInfiniteQuery({
    queryKey: ["getCommentsInfiniteQuery"],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    enabled: isAuthenticated,
    queryFn: async ({ pageParam = 1 }) => {
      const res = await axios.get(
        `${BASE_URL}/comment/${
          eventId ? `?event_id=${eventId}&` : "?"
        }page=${pageParam}&page_size=12&sort=datetime&desc=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            sid: sid,
          },
        }
      );
      const pageData = res.data.data;

      return {
        data: pageData,
        previousCursor: pageParam > 1 ? pageParam - 1 : 1,
        nextCursor: pageParam < pageData.pages ? pageParam + 1 : undefined,
      };
    },
    getPreviousPageParam: (lastPage) => lastPage.previousCursor,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    onError: (e) => {
      if (checkForInvalidToken(e)) return;

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  const allCommentItems = useMemo(() => {
    if (!getCommentsInfiniteQuery.data) return [];

    const items = [];
    for (let index in getCommentsInfiniteQuery.data.pages) {
      const page = getCommentsInfiniteQuery.data.pages[index];
      items.push(...page.data.items);
    }

    return items;
  }, [getCommentsInfiniteQuery.data]);

  if (!isAuthenticated) return <></>;

  return (
    <>
      <VStack {...mainVStackProps}>
        <Flex
          w="100%"
          mt="2.5rem"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={{ base: "1.5rem", md: undefined }}
        >
          <Flex flexDirection="row" alignItems="center" gap=".5rem">
            <Icon as={RiDiscussFill} boxSize={5} />
            <Heading as="h1" size="sm">
              {`نظرات کاربران ${
                getCommentsInfiniteQuery.data
                  ? getCommentsInfiniteQuery.data.pages[0].data.count > 0
                    ? `(${getCommentsInfiniteQuery.data.pages[0].data.count} نظر)`
                    : ""
                  : ""
              }`}
            </Heading>
          </Flex>

          {eventId ? (
            <Button
              colorScheme="gray"
              leftIcon={<BiMessageAdd />}
              onClick={() => registerNewCommentDrawerDisclosure.onOpen()}
            >
              ثبت نظر
            </Button>
          ) : null}
        </Flex>

        {!getCommentsInfiniteQuery.data ||
        ((getCommentsInfiniteQuery.isLoading ||
          getCommentsInfiniteQuery.isRefetching) &&
          !getCommentsInfiniteQuery.isFetchingNextPage) ? (
          <Box w="100%" overflowX="scroll" className={styles.comments}>
            <Box w="max-content" className={styles.comments__list}>
              {Array(6)
                .fill(0)
                .map((_, index) => (
                  <CommentSkeleton key={`CommentSkeletonItem-${index}`} />
                ))}
            </Box>
          </Box>
        ) : allCommentItems.length > 0 ? (
          <Box w="100%" overflowX="scroll" className={styles.comments}>
            <Box
              w="max-content"
              className={`${styles.comments__list} comments_list_root`}
            >
              {allCommentItems.map((comment) => (
                <CommentItem
                  key={`CommentItem-${comment.id}`}
                  comment={comment}
                />
              ))}

              {getCommentsInfiniteQuery.hasNextPage ? (
                <Button
                  h="max-content"
                  flexDirection="column"
                  gap=".5rem"
                  p="4.5rem 2.5rem"
                  colorScheme="gray"
                  borderRadius="lg"
                  mx=".5rem"
                  isLoading={getCommentsInfiniteQuery.isFetchingNextPage}
                  onClick={() => getCommentsInfiniteQuery.fetchNextPage()}
                >
                  <Icon
                    as={RiDownloadCloud2Line}
                    fontSize="4xl"
                    color="var(--c--subtitle)"
                  />

                  <Text color="var(--c--subtitle)">نمایش بیشتر</Text>
                </Button>
              ) : null}
            </Box>
          </Box>
        ) : (
          <Center w="100%">
            <VStack my="2rem" justify="center" spacing="1.5rem">
              <lord-icon
                src="https://cdn.lordicon.com/egmlnyku.json"
                trigger="loop"
                delay="2000"
                style={{ width: "100px", height: "100px" }}
              ></lord-icon>

              <Text>هنوز هیچ نظری ثبت نشده است!</Text>

              {eventId ? (
                <Button
                  leftIcon={<BiMessageAdd />}
                  colorScheme="telegram"
                  onClick={() => registerNewCommentDrawerDisclosure.onOpen()}
                >
                  ثبت اولین نظر
                </Button>
              ) : null}
            </VStack>
          </Center>
        )}
      </VStack>

      <CommentInfoDrawer
        eventId={eventId}
        isOpen={registerNewCommentDrawerDisclosure.isOpen}
        onClose={registerNewCommentDrawerDisclosure.onClose}
      />
    </>
  );
}
