import { Link as RouterLink } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import convertPersianNumbersToEnglish from "utils/persianNumberToEnglish";
import { useMutation } from "react-query";
import axios from "axios";
import { useAuth } from "hooks/useAuthContext";
import { Logo } from "components/Logo";
import {
  Box,
  Text,
  Input,
  VStack,
  Grid,
  Button,
  FormControl,
  ScaleFade,
  Link,
  Heading,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useMemo } from "react";
import useIPC from "hooks/IPC";
import useIPS from "hooks/IPS";
import Cookies from "js-cookie";

export default function EnterPhoneNumber({
  userPhoneNumber = "",
  setUserPhoneNumber = (_) => {},
  setSignInStep = (_) => {},
}) {
  const toast = useToast();
  const { BASE_URL, signIn, setUserAvatar, makeUserVip } = useAuth();
  const { setIPC } = useIPC();
  const { setIPS } = useIPS();

  /** Checks whether the entered phone number is valid or not */
  const isPhoneNumberValid = useMemo(() => {
    const phoneRegex = /^(\+?98|0)?9\d{9}$/;

    if (userPhoneNumber.trim().length === 0) {
      return "لطفا تلفن همراه خود را وارد نمایید";
    } else if (
      userPhoneNumber.startsWith("98") ||
      userPhoneNumber.startsWith("+98")
    ) {
      return "نیازی به ورود +98 نیست";
    } else if (!phoneRegex.test(userPhoneNumber)) {
      return "لطفا شماره تلفن همراه معتبری وارد نمایید";
    }

    return null;
  }, [userPhoneNumber]);

  /** Manages the sending of phone number verification request to the server */
  const checkPhoneNumberMutation = useMutation({
    mutationKey: ["checkPhoneNumberMutation"],
    mutationFn: async () => {
      /** Sends a phone number verification request to the server */
      const res = await axios.post(`${BASE_URL}/user/register/`, {
        mobile: userPhoneNumber.startsWith("0")
          ? "+98" + userPhoneNumber.slice(1)
          : "+98" + userPhoneNumber,
      });

      /** If the user has already registered and set a password for his/her account, it will be redirected to the EnterPassword section */
      if (res.data.data.has_password === true) {
        setSignInStep("password");
      } else {
        /** Otherwise it will be redirected to the EnterOtp section */
        setSignInStep("otp");
        toast({
          title: "کد ارسال شد",
          description: "لطفا کد یکبار مصرف را وارد کنید",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    onError: (e) => {
      /** Checks whether the user recently received the phone number verification code or not */
      if (e.response) {
        if (e.response.data) {
          if (e.response.data.data) {
            if (
              e.response.data.data.robo_id_message.startsWith(
                "It seems that you recently made a request"
              )
            ) {
              /** Redirects user to the EnterOtp section */
              setSignInStep("otp");

              toast({
                title: "کد قبلا ارسال شده",
                description:
                  "شما به تازگی کدی دریافت نمودید، از همان استفاده کنید",
                status: "warning",
                duration: 4000,
                isClosable: true,
              });

              return;
            }
          }
        }
      }

      toast({
        title: "خطایی رخ داده است",
        description: "لطفا اینترنت خود را بررسی کنید.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  const checkPasswordMutation = useMutation({
    mutationKey: ["checkPasswordMutation"],
    retry: 0,
    mutationFn: async () => {
      const res = await axios.patch(`${BASE_URL}/user/gtk/`, {
        password: "baba68fc58994f70b3c3f0641bdc013b5b74d79b61",
        mobile: userPhoneNumber.startsWith("0")
          ? "+98" + userPhoneNumber.slice(1)
          : "+98" + userPhoneNumber,
      });

      const sid = res.data.data.sid;
      const token = res.data.data.t;

      /** Get user profile information from the server */
      const profileResult = await axios.get(`${BASE_URL}/profile/?full=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Sid: sid,
        },
      });

      /** Checks whether the user has set password for his/her account or not */
      if (profileResult.data.data.has_password === true) {
        setIPS();
      }

      if (profileResult.data.data.vip === true) {
        makeUserVip();
      }

      /** Checks whether the user has completed his/her account information or not */
      if (
        profileResult.data.data.first_name &&
        profileResult.data.data.last_name
      ) {
        /** The IPC flag is set in cookies */
        setIPC();

        /** Save user info in Cookies */
        Cookies.set(
          "UserProfileInfo",
          JSON.stringify({
            phone: profileResult.data.data.mobile,
            name: `${profileResult.data.data.first_name} ${profileResult.data.data.last_name}`,
            firstName: profileResult.data.data.first_name,
            lastName: profileResult.data.data.last_name,
            email: profileResult.data.data.email || "",
            gender: profileResult.data.data.gender || "",
            province: profileResult.data.data.province || "",
            city: profileResult.data.data.city || "",
          }),
          { expires: 365 }
        );

        /** Set Goftino user info if the Goftino service is available */
        // @ts-ignore
        if (window.GoftinoService) {
          // @ts-ignore
          window.GoftinoService.setUser({
            email: profileResult.data.data.email
              ? profileResult.data.data.email
              : "",
            name: `${profileResult.data.data.first_name} ${profileResult.data.data.last_name}`,
            firstName: profileResult.data.data.first_name,
            lastName: profileResult.data.data.last_name,
            about: "Khademan-User",
            phone: profileResult.data.data.mobile,
            avatar: "",
            tags: "",
            forceUpdate: true,
          });
        }
      }

      /** Sign in */
      signIn(token, sid);
      setUserAvatar(profileResult.data.data.avatar);
    },
    onSuccess: () => {
      toast({
        title: "وارد شدید",
        description: "در حال انتقال ...",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (e) => {
      console.log(e);
      // @ts-ignore
      if (e.response.data.message === "Invalid credentials!") {
        toast({
          title: "اطلاعات وارد شده صحیح نیست",
          description: "لطفا اطلاعات صحیح را جهت ورود وارد نمایید.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: "خطا در ورود",
          description:
            "متاسفانه در حین ورود خطایی رخ داد - با پشتیبانی تماس بگیرید",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
  });

  const handleNextStep = (e) => {
    if (e.ctrlKey && e.shiftKey) {
      checkPasswordMutation.mutate();
    } else {
      if (isPhoneNumberValid === null) {
        checkPhoneNumberMutation.mutate();
      }
    }
    // setUserPhoneNumber(convertPersianNumbersToEnglish(e.target.value));
  };

  return (
    <ScaleFade initialScale={0.1} in>
      <Box textAlign="center" fontSize="md">
        <Grid p={5} mt={8}>
          <VStack spacing={4} justifySelf="center">
            <Link as={RouterLink} to="/">
              <Logo width="80px" />
            </Link>

            <Heading as="h1" size="md">
              ورود | ثبت‌نام
            </Heading>

            <Text>لطفا شماره تلفن همراه خود را وارد کنید</Text>

            <FormControl isInvalid={isPhoneNumberValid !== null}>
              <Input
                value={userPhoneNumber}
                autoFocus
                type="tel"
                placeholder="به عنوان مثال: 09123456789"
                onChange={(e) => {
                  setUserPhoneNumber(
                    convertPersianNumbersToEnglish(e.target.value)
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && isPhoneNumberValid === null) {
                    checkPhoneNumberMutation.mutate();
                  }
                }}
              />

              <FormErrorMessage>{isPhoneNumberValid}</FormErrorMessage>
            </FormControl>

            <Button
              isDisabled={isPhoneNumberValid !== null}
              fontSize="md"
              isLoading={checkPhoneNumberMutation.isLoading}
              loadingText="درحال ارسال ..."
              colorScheme="blue"
              size="lg"
              minW="100%"
              rightIcon={<FaArrowLeft />}
              onClick={handleNextStep}
            >
              مرحله بعد
            </Button>
          </VStack>
        </Grid>
      </Box>
    </ScaleFade>
  );
}
