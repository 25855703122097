import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import LoadingFallback from "../../components/LoadingFallback";
import { Container } from "@chakra-ui/react";
import TopBar from "../../components/TopBar";

export default function MainLayout() {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <TopBar />
      <Container
        maxW="5xl"
        minH={{ lg: "66dvh" }}
        mb={{ base: "1rem", lg: "1.5rem" }}
      >
        <Header />
        <Outlet />
      </Container>
    </Suspense>
  );
}
