import { useEffect, useState } from "react";
import numberToPersian from "../../utils/numberToPersian";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "hooks/useAuthContext";
import { AxiosError } from "axios";
import PageHeader from "../../components/PageHeader";
import parse from "html-react-parser";
import {
  ScaleFade,
  Flex,
  Text,
  Card,
  CardBody,
  FormLabel,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Button,
  Skeleton,
  Tag,
  Alert,
  AlertIcon,
  Box,
  Input,
  Select,
  VStack,
  Textarea,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import CustomNumberInput from "../../components/CustomNumberInput";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useDefaultToast from "hooks/useDefaultToast";
import { ApiResponse, Bill } from "../../types/apiTypes";

export default function PayVip() {
  const supportSlug = "financial_support";
  const { authorizedAxios, checkForInvalidToken } = useAuth();
  const showToast = useDefaultToast();
  const [searchParams] = useSearchParams();

  const stateBill = useLocation().state?.bill as Bill | undefined;

  const { subject } = useParams<{ subject: string }>();
  const navigate = useNavigate();

  const getBillQuery = useQuery({
    queryKey: ["getBillQuery"],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    queryFn: async () => {
      if (stateBill) return stateBill;

      const res = await authorizedAxios.get<ApiResponse<Bill>>(
        `/bill/?subject=` + subject
      );
      const resBill = res.data.data.items[0];
      if (!resBill) {
        navigate("/");
        showToast({
          title: "پرداخت مورد نظر یافت نشد!",
          description:
            "آدرس را بررسی نمایید در صورت اطمینان از آن با پشتیبان موضوع را درمیان گذارید.",
          id: "getBillQuery_UNHANDLED_ERROR",
          status: "error",
        });
        return undefined;
      }

      return resBill;
    },
    onError: (e: AxiosError) => {
      //TODO Handle it globaly
      if (checkForInvalidToken(e)) return;

      //maybe net error
      if (e.code === "ERR_NETWORK") {
        //ERR_NETWORK error
        return showToast({
          title: "در برقراری ارتباط با سرور خطایی روی داد!",
          description: "لطفا اتصال اینترنت خود را بررسی نمایید.",
          id: "ERR_NETWORK",
          status: "error",
        });
      }

      //unhandled error
      return showToast({
        title: "در دریافت پرداخت خطایی روی داد!",
        description:
          "باعرض پوزش در دریافت پرداخت خطایی روی داد از اینکه شکیبایی می کنید متشکریم.",
        id: "getBillQuery_UNHANDLED_ERROR",
        status: "error",
      });
    },
  });

  //its ok to call useEffect every time
  const bill = getBillQuery.data || ({} as Bill);

  const [donateAmount, setDonateAmount] = useState<number>(0);
  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({});

  const handleInputChange = (fieldName: string, value: string) => {
    setFormValues(prev => ({
      ...prev,
      [fieldName]: value
    }));
    setFormErrors(prev => ({
      ...prev,
      [fieldName]: false
    }));
  };

  const validateForm = () => {
    const errors: Record<string, boolean> = {};
    let isValid = true;

    if (bill.extra_info?.fields) {
      Object.entries(bill.extra_info.fields).forEach(([key, field]) => {
        if (field.isRequired && (!formValues[key] || formValues[key].trim() === '')) {
          errors[key] = true;
          isValid = false;
        }
      });
    }

    setFormErrors(errors);
    return isValid;
  };

  const isFormValid = () => {
    if (!bill.extra_info?.fields) return true;

    // بررسی مبلغ پرداخت
    if (!donateAmount || donateAmount === 0) return false;

    // بررسی فیلدهای اجباری
    for (const [key, field] of Object.entries(bill.extra_info.fields)) {
      if (field.isRequired && (!formValues[key] || formValues[key].trim() === '')) {
        return false;
      }
    }

    return true;
  };

  const focusFirstEmptyField = () => {
    if (!bill.extra_info?.fields) return;

    for (const [key, field] of Object.entries(bill.extra_info.fields)) {
      if (field.isRequired && (!formValues[key] || formValues[key].trim() === '')) {
        const element = document.getElementById(`field-${key}`);
        if (element) {
          element.focus();
          setFormErrors(prev => ({
            ...prev,
            [key]: true
          }));
        }
        return;
      }
    }
  };

  const handlePaymentClick = () => {
    if (!isFormValid()) {
      focusFirstEmptyField();
      return;
    }
    getPaymentLinkMutation.mutate();
  };

  const renderFormField = (fieldName: string, field: any) => {
    switch (field.keyType) {
      case 'TextField':
        return (
          <Input
            id={`field-${fieldName}`}
            placeholder={field.placeholder}
            value={formValues[fieldName] || ''}
            onChange={(e) => handleInputChange(fieldName, e.target.value)}
            isInvalid={formErrors[fieldName]}
            required={field.isRequired}
            autoFocus={formErrors[fieldName]}
          />
        );
      case 'TextArea':
        return (
          <Textarea
            id={`field-${fieldName}`}
            placeholder={field.placeholder}
            value={formValues[fieldName] || ''}
            onChange={(e) => handleInputChange(fieldName, e.target.value)}
            isInvalid={formErrors[fieldName]}
            required={field.isRequired}
            rows={4}
            autoFocus={formErrors[fieldName]}
          />
        );
      case 'Select':
        return (
          <Select
            id={`field-${fieldName}`}
            placeholder={field.placeholder}
            value={formValues[fieldName] || ''}
            onChange={(e) => handleInputChange(fieldName, e.target.value)}
            isInvalid={formErrors[fieldName]}
            required={field.isRequired}
            autoFocus={formErrors[fieldName]}
          >
            {Object.entries(field.values).map(([value, label]) => (
              <option key={value} value={value}>
                {label as string}
              </option>
            ))}
          </Select>
        );
      case 'Radio':
        return (
          <Box id={`field-${fieldName}`}>
            <RadioGroup
              value={formValues[fieldName] || ''}
              onChange={(value) => handleInputChange(fieldName, value)}
            >
              <Stack direction="row" spacing={5}>
                {Object.entries(field.values).map(([value, label]) => (
                  <Radio
                    key={value}
                    value={value}
                    isRequired={field.isRequired}
                    isInvalid={formErrors[fieldName]}
                  >
                    {label as string}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </Box>
        );
      case 'Video':
        const videoSrc = Object.values(field.values)[0];
        return (
          <Box>
            <video
              src={typeof videoSrc === "string" ? videoSrc : ""}
              width="100%"
              style={{ borderRadius: 10 }}
              controls
            />
          </Box>
        );
      case 'Audio':
        const audioSrc = Object.values(field.values)[0];
        return (
          <Box>
            <audio
              src={typeof audioSrc === "string" ? audioSrc : ""}
              controls
              style={{ width: "100%" }}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  /** Manage the request to receive the payment link */
  const getPaymentLinkMutation = useMutation({
    mutationKey: ["getPaymentLinkMutation", { slug: supportSlug }],
    retry: 0,
    mutationFn: async () => {
      if (getBillQuery.isLoading) return null;

      const hemayatBillId = bill._id;
      const min_amount = getBillQuery?.data?.min_amount;
      if (min_amount && donateAmount < min_amount) {
        showToast({
          title: "مبلغ را اصلاح کنید!",
          description: `مبلغ نمیتواند کمتر از ${min_amount.toLocaleString(
            "fa"
          )} تومان باشد `,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // ساختار extra_info برای ارسال به سرور
      const extra_info: Record<string, string> = {};
      if (bill.extra_info?.fields) {
        Object.entries(bill.extra_info.fields).forEach(([key, field]) => {
          if (formValues[key]) {
            extra_info[key] = formValues[key];
          }
        });
      }

      /** Send a request to get the payment link */
      const getPaymentLinkRes = await authorizedAxios.post(`/pay/`, {
        amount: donateAmount,
        bill_id: hemayatBillId,
        redirection_url: "https://shamim313.com/p/r/",
        extra_info: extra_info
      });

      return getPaymentLinkRes.data.data.payment_url;
    },
    onSuccess: (paymentUrl) => {
      if (paymentUrl) {
        showToast({
          title: "در حال انتقال به درگاه پرداخت",
          status: "success",
          duration: 4000,
          isClosable: true,
        });

        /** Open payment portal page */
        window.open(paymentUrl, "_self");
      }
    },
    onError: (e: AxiosError<any>) => {
      if (checkForInvalidToken(e)) return;

      if (e.response?.data) {
        if (e.response.data.message.includes("Zarinpal error")) {
          showToast({
            title: "خطای درگاه پرداخت",
            description: "ممکن است مبلغ وارد شده مجاز نباشد، آنرا بررسی کنید!",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        }
      } else {
        showToast({
          title: "خطایی رخ داد",
          description: "لطفا اینترنت خود را بررسی کنید.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });

        // toast({
        //   title: `کد خطا: ${e.response.status}`,
        //   description: JSON.stringify(e.response.data, null, 2),
        //   status: "error",
        //   duration: 4000,
        //   isClosable: true,
        // });
      }
    },
  });

  useEffect(() => {
    const paymentStatus = searchParams.get("ps");
    if (!paymentStatus) return;
    if (!bill?.title) return;

    if (paymentStatus) {
      if (paymentStatus === "200") {
        showToast({
          title: "پرداخت شما با موفقیت انجام شد",
          description: "موضوع پرداخت : " + bill.title,
          status: "success",
          id: "SuccessPay",
          duration: 6666,
        });
      } else {
        showToast({
          title: "متاسفانه، پرداخت با موفقیت انجام نشد!",
          description: "در صورت تمایل می توانید دوباره اقدام کنید.",
          status: "error",
          id: "ErrorPay",
          duration: 6666,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, bill]);

  useEffect(() => {
    if (
      getBillQuery.data?.amount !== undefined &&
      getBillQuery.data?.amount !== null
    ) {
      setDonateAmount(getBillQuery.data.amount);
    }
  }, [getBillQuery.data]);

  return (
    <ScaleFade initialScale={0.1} in>
      <Flex w="100%" flexDirection="column" gap={5}>
        <PageHeader
          //@ts-ignore
          title={
            <>
              {bill.title}
              {bill.vip && (
                <Tag colorScheme="green" mr={2}>
                  خانواده
                </Tag>
              )}
            </>
          }
          drawerTitle="راهنمای پرداخت"
          hasCard
        >
          <Flex flexDirection="column" alignItems="stretch" gap="1rem">
            <Text textAlign="justify">
              برای حمایت از کانون خادمان امام زمان (عج) و عضویت در خانواده شمیم
              مهدوی
            </Text>
          </Flex>
        </PageHeader>

        <Flex
          flexDirection={{ base: "column", md: "row" }}
          alignItems="flex-start"
          gap={0}
        >
          <Card w="100%" flex="1">
            <Skeleton isLoaded={!getBillQuery.isLoading} zIndex={0}>
              <CardBody zIndex={0}>
                <Box>{parse(`${getBillQuery.data?.description}`)}</Box>
                {getBillQuery.data?.extra_info.title && (
                  <Text textAlign="center" fontWeight={700}>
                    {getBillQuery.data?.extra_info.title}
                  </Text>
                )}

                {bill.extra_info?.fields && (
                  <VStack spacing={4} mt={4}>
                    {Object.entries(bill.extra_info.fields).map(([key, field]) => (
                      <FormControl key={key} isInvalid={formErrors[key]}>
                        <FormLabel>
                          {field.description}
                          {field.isRequired && (
                            <Text as="span" color="red.500" ml={1}>
                              *
                            </Text>
                          )}
                        </FormLabel>
                        {renderFormField(key, field)}
                        {formErrors[key] && (
                          <FormErrorMessage>
                            این فیلد الزامی است
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    ))}
                  </VStack>
                )}

                <FormControl isInvalid={!Boolean(donateAmount)}>
                  <FormLabel mt={6}>مبلغ (به تومان)</FormLabel>

                  <CustomNumberInput
                    defaultValue={getBillQuery.data?.amount ?? undefined}
                    step={50000}
                    min={0}
                    inputBgColor="#40e0e324"
                    //@ts-ignore
                    onChange={(value) => setDonateAmount(+value)}
                  />

                  {!isNaN(donateAmount) ? (
                    <FormHelperText
                      fontSize="md"
                      textAlign="center"
                      mt=".75rem"
                      padding=".25rem 1rem"
                      borderRadius="md"
                    >
                      {`${numberToPersian(donateAmount)} تومان`}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <Button
                  w="100%"
                  mt={3}
                  colorScheme="telegram"
                  isLoading={getPaymentLinkMutation.isLoading}
                  onClick={handlePaymentClick}
                  className={
                    donateAmount || donateAmount > 0
                      ? "glowing_golden_border"
                      : ""
                  }
                >
                  پرداخت
                </Button>
                <Link
                  to={`/p/${bill.subject}/mp/`}
                  state={{ title: bill.title, id: bill._id }}
                >
                  <Button w="100%" mt={3} variant="ghost">
                    ثبت دستی فیش کارت به کارت
                  </Button>
                </Link>
              </CardBody>
            </Skeleton>
          </Card>
        </Flex>
      </Flex>
    </ScaleFade>
  );
}
