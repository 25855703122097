import { useAuth } from "hooks/useAuthContext";
import { useState } from "react";
import useIPC from "hooks/IPC";
import useIPS from "hooks/IPS";
import { useMutation } from "react-query";
import { useSearchParams, useNavigate } from "react-router-dom";
import { FaEdit, FaSms } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Box,
  Text,
  Input,
  VStack,
  Grid,
  Button,
  FormControl,
  ScaleFade,
  useToast,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

interface EnterPasswordProps {
  userPhoneNumber: string;
  setSignInStep: (step: string) => void;
  stayInPage?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

const EnterPassword: React.FC<EnterPasswordProps> = ({
  userPhoneNumber,
  setSignInStep,
  stayInPage,
  setIsOpen,
}) => {
  const { BASE_URL, signIn, setUserAvatar, makeUserVip } = useAuth();
  const [password, setPassword] = useState("");
  const toast = useToast();
  const { setIPC } = useIPC();
  const { setIPS } = useIPS();
  const [searchParams, setSearchParams] = useSearchParams();
  const toParam = searchParams.get("to");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(true);
  const handleShowPasswordClick = () => setShowPassword(!showPassword);

  /** Manages the sending of password verification request to the server */
  const checkPasswordMutation = useMutation({
    mutationKey: ["checkPasswordMutation"],
    retry: 0,
    mutationFn: async () => {
      /** Sends a password verification request to the server */
      const res = await axios.post(`${BASE_URL}/user/login/`, {
        password: password,
        otp: "",
        mobile: userPhoneNumber.startsWith("0")
          ? "+98" + userPhoneNumber.slice(1)
          : "+98" + userPhoneNumber,
      });

      const sid = res.data.data.sid;
      const token = res.data.data.token;

      /** Get user profile information from the server */
      const profileResult = await axios.get(`${BASE_URL}/profile/?full=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Sid: sid,
        },
      });

      /** Checks whether the user has set password for his/her account or not */
      if (profileResult.data.data.has_password === true) {
        setIPS();
      }

      if (profileResult.data.data.vip === true) {
        makeUserVip();
      }

      /** Checks whether the user has completed his/her account information or not */
      if (
        // profileResult.data.data.national_code &&
        profileResult.data.data.first_name &&
        profileResult.data.data.last_name
        // profileResult.data.data.gender &&
        // profileResult.data.data.marital_status &&
        // profileResult.data.data.birth_date
      ) {
        /** The IPC flag is set in cookies */
        setIPC();

        /** Save user info in Cookies */
        Cookies.set(
          "UserProfileInfo",
          JSON.stringify({
            phone: profileResult.data.data.mobile,
            name: `${profileResult.data.data.first_name} ${profileResult.data.data.last_name}`,
            firstName: profileResult.data.data.first_name,
            lastName: profileResult.data.data.last_name,
            email: profileResult.data.data.email || "",
            gender: profileResult.data.data.gender || "",
            province: profileResult.data.data.province || "",
            city: profileResult.data.data.city || "",
          }),
          { expires: 365 }
        );

        /** Set Goftino user info if the Goftino service is available */
        // @ts-ignore
        if (window.GoftinoService) {
          // @ts-ignore
          window.GoftinoService.setUser({
            email: profileResult.data.data.email
              ? profileResult.data.data.email
              : "",
            name: `${profileResult.data.data.first_name} ${profileResult.data.data.last_name}`,
            firstName: profileResult.data.data.first_name,
            lastName: profileResult.data.data.last_name,
            about: "Khademan-User",
            phone: profileResult.data.data.mobile,
            avatar: "",
            tags: "",
            forceUpdate: true,
          });
        }
      }

      /** Sign in */
      signIn(token, sid);
      setUserAvatar(profileResult.data.data.avatar);
    },
    onSuccess: () => {
      toast({
        title: "وارد شدید",
        description: "در حال انتقال ...",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      /** Takes the user to the place he/she came from (if specified) or to the home page. */
      if (toParam) {
        navigate(toParam, { replace: true });
      } else if (stayInPage && setIsOpen) {
        setIsOpen(false);
      } else {
        navigate("/", { replace: true });
      }
    },
    onError: (e: any) => {
      console.log(e);
      if (e.response.data.message === "Invalid credentials!") {
        toast({
          title: "اطلاعات وارد شده صحیح نیست",
          description: "لطفا اطلاعات صحیح را جهت ورود وارد نمایید.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: "خطا در ورود",
          description:
            "متاسفانه در حین ورود خطایی رخ داد - با پشتیبانی تماس بگیرید",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  /** Manages the sending of the phone number verification code */
  const sendOtpMutation = useMutation({
    mutationKey: ["sendOtpMutation"],
    mutationFn: async () => {
      /** Sends a request to send the phone number verification code */
      await axios.post(`${BASE_URL}/user/otp/`, {
        via: "mobile",
        value: userPhoneNumber.startsWith("0")
          ? "+98" + userPhoneNumber.slice(1)
          : "+98" + userPhoneNumber,
      });
    },
    onSuccess: () => {
      /** Redirects the user to the EnterOtp section */
      setSignInStep("otp");

      toast({
        title: "کد ارسال شد",
        description: "لطفا کد یکبار مصرف را وارد کنید",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (e: any) => {
      /** Checks whether the user recently received the phone number verification code or not */
      if (e.response) {
        if (e.response.data) {
          if (
            e.response.data.message.startsWith(
              "You can request OTP only once in 2 minutes!"
            )
          ) {
            /** Redirects the user to the EnterOtp section */
            setSignInStep("otp");

            toast({
              title: "کد قبلا ارسال شده",
              description:
                "شما به تازگی کدی دریافت نمودید، از همان استفاده کنید",
              status: "warning",
              duration: 4000,
              isClosable: true,
            });
          }
        }
      } else {
        toast({
          title: "خطایی رخ داده است",
          description: "لطفا اینترنت خود را بررسی کنید.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });

        // toast({
        //   title: `کد خطا: ${e.response.status}`,
        //   description: JSON.stringify(e.response.data, null, 2),
        //   status: "error",
        //   duration: 4000,
        //   isClosable: true,
        // });
      }
    },
  });

  const askForChangePasswordMutation = useMutation({
    mutationKey: ["askForChangePasswordMutation"],
    mutationFn: async () => {
      /** Sends a request to send the phone number verification code */
      await axios.post(`${BASE_URL}/user/otp/`, {
        via: "mobile",
        value: userPhoneNumber.startsWith("0")
          ? "+98" + userPhoneNumber.slice(1)
          : "+98" + userPhoneNumber,
      });
    },
    onSuccess: () => {
      /** Redirects the user to the EnterOtp section */
      setSignInStep("otp");

      toast({
        title: "کد ارسال شد",
        description: "لطفا کد یکبار مصرف را وارد کنید",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (e: any) => {
      /** Checks whether the user recently received the phone number verification code or not */
      if (e.response) {
        if (e.response.data) {
          if (
            e.response.data.message.startsWith(
              "It seems that you recently made a request"
            )
          ) {
            /** Redirects the user to the EnterOtp section */
            setSignInStep("otp");

            toast({
              title: "کد قبلا ارسال شده",
              description:
                "شما به تازگی کدی دریافت نمودید، از همان استفاده کنید",
              status: "warning",
              duration: 4000,
              isClosable: true,
            });
          }
        }
      } else {
        toast({
          title: "خطایی رخ داده است",
          description: "لطفا اینترنت خود را بررسی کنید.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });

        // toast({
        //   title: `کد خطا: ${e.response.status}`,
        //   description: JSON.stringify(e.response.data, null, 2),
        //   status: "error",
        //   duration: 4000,
        //   isClosable: true,
        // });
      }
    },
  });

  return (
    <ScaleFade initialScale={0.1} in>
      <Box textAlign="center" fontSize="md">
        <Grid p={5} mt={10}>
          <VStack spacing={4} justifySelf="center">
            <Button
              sx={{ direction: "ltr" }}
              onClick={() => {
                setSignInStep("phone");
              }}
              fontSize="sm"
              size="sm"
              leftIcon={<FaEdit />}
            >
              {userPhoneNumber}
            </Button>
            {/* @ts-ignore */}
            <lord-icon
              src="https://cdn.lordicon.com/yideukey.json"
              trigger="loop"
              style={{ width: "150px", height: "150px" }}
            >
              {/* @ts-ignore */}
            </lord-icon>

            <Text size="small">رمز عبور خود را وارد کنید</Text>
            <Text size="small" color="red.500">
              رمز عبور پیش فرض ۴ رقم آخر شماره موبایل شماست
            </Text>

            <FormControl>
              <InputGroup size="md">
                <Input
                  value={password}
                  autoFocus
                  type={showPassword ? "text" : "password"}
                  sx={{ direction: "ltr" }}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      checkPasswordMutation.mutate();
                    }
                  }}
                  pl={4}
                  pr={20}
                />

                <InputLeftElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleShowPasswordClick}
                  >
                    {showPassword ? "مخفی" : "نمایش"}
                  </Button>
                </InputLeftElement>
              </InputGroup>
            </FormControl>

            <Button
              fontSize="md"
              isLoading={checkPasswordMutation.isLoading}
              isDisabled={password.length === 0}
              loadingText="درحال ورود ..."
              colorScheme="blue"
              size="lg"
              minW="100%"
              rightIcon={<FaArrowLeft />}
              onClick={() => {
                checkPasswordMutation.mutate();
              }}
            >
              ورود به سایت
            </Button>

            <Button
              loadingText="درحال ارسال کد ..."
              isLoading={askForChangePasswordMutation.isLoading}
              isDisabled={sendOtpMutation.isLoading}
              fontSize="md"
              variant="outline"
              size="lg"
              minW="100%"
              onClick={() => {
                askForChangePasswordMutation.mutateAsync().then(() => {
                  setSearchParams({ to: "/u/p/change-password" });
                });
              }}
            >
              فراموشی رمز عبور
            </Button>

            <Button
              loadingText="درحال ارسال کد ..."
              isLoading={sendOtpMutation.isLoading}
              isDisabled={askForChangePasswordMutation.isLoading}
              fontSize="md"
              variant="outline"
              size="lg"
              minW="100%"
              leftIcon={<FaSms />}
              onClick={() => {
                sendOtpMutation.mutate();
              }}
            >
              ورود با پیامک
            </Button>
          </VStack>
        </Grid>
      </Box>
    </ScaleFade>
  );
};

export default EnterPassword;
