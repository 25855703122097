import { useAuth } from "hooks/useAuthContext";
import { useMemo, useState } from "react";
import useIPC from "hooks/IPC";
import useIPS from "hooks/IPS";
import { useMutation } from "react-query";
import { useSearchParams, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import useDefaultToast from "hooks/useDefaultToast";
import IranProvinces from "assets/library/iranProvinces";
import IranCities from "assets/library/iranCities";
import {
  Box,
  Input,
  VStack,
  Grid,
  Button,
  FormControl,
  ScaleFade,
  useToast,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Select,
} from "@chakra-ui/react";

export default function EnterProfile({
  userPhoneNumber = "",
  setSignInStep = (_) => {},
  setIsOpen = () => {},
  stayInPage = false,
}) {
  const { BASE_URL, signIn, setUserAvatar, token, sid } = useAuth();
  const [password, setPassword] = useState(userPhoneNumber.slice(-4));
  const toast = useToast();
  const showToast = useDefaultToast();
  const { setIPC } = useIPC();
  const { setIPS } = useIPS();
  const [searchParams] = useSearchParams();
  const toParam = searchParams.get("to");
  const navigate = useNavigate();

  const [userFName, setUserFName] = useState("");
  const [userLName, setUserLName] = useState("");
  const [userProvince, setUserProvince] = useState("");
  const [userCity, setUserCity] = useState("");

  const [show, setShow] = React.useState(true);
  const handleClick = () => setShow(!show);

  const cityOptions = useMemo(() => {
    if (userProvince.length > 0) {
      return IranCities[userProvince];
    }

    return [];
  }, [userProvince]);

  /** Manages the sending of password verification request to the server */
  const checkPasswordMutation = useMutation({
    mutationKey: ["checkPasswordMutation"],
    retry: 0,
    mutationFn: async () => {
      if (
        !userFName.trim().length ||
        !userLName.trim().length ||
        !password.trim().length
      ) {
        showToast({
          title: "اطلاعات وارد شده صحیح نیست",
          description: "لطفا اطلاعات مورد نظر را کامل کنید.",
          status: "error",
          id: "enterProfile",
        });
        return;
      }

      await axios.put(
        `${BASE_URL}/user/password/`,
        {
          password: password,
          otp: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Sid: sid,
          },
        }
      );

      await axios.put(
        `${BASE_URL}/user/compreg/`,
        {
          first_name: userFName,
          last_name: userLName,
          city: userCity,
          province: userProvince,
          gender: "male",
          marital_status: "single",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Sid: sid,
          },
        }
      );

      /** Get user profile information from the server */
      const profileResult = await axios.get(`${BASE_URL}/profile/?full=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Sid: sid,
        },
      });

      /** Checks whether the user has set password for his/her account or not */
      if (profileResult.data.data.has_password === true) {
        setIPS();
      }

      /** Checks whether the user has completed his/her account information or not */
      if (
        // profileResult.data.data.national_code &&
        profileResult.data.data.first_name &&
        profileResult.data.data.last_name
        // profileResult.data.data.gender &&
        // profileResult.data.data.marital_status &&
        // profileResult.data.data.birth_date
      ) {
        /** The IPC flag is set in cookies */
        setIPC();

        /** Save user info in Cookies */
        Cookies.set(
          "UserProfileInfo",
          JSON.stringify({
            phone: profileResult.data.data.mobile,
            name: `${profileResult.data.data.first_name} ${profileResult.data.data.last_name}`,
            firstName: profileResult.data.data.first_name,
            lastName: profileResult.data.data.last_name,
            email: profileResult.data.data.email ?? "",
            gender: profileResult.data.data.gender ?? "",
            province: profileResult.data.data.province ?? "",
            city: profileResult.data.data.city ?? "",
          }),
          { expires: 365 }
        );

        /** Set Goftino user info if the Goftino serivce is available */
        // if (window.GoftinoService) {
        //   window.GoftinoService.setUser({
        //     email: profileResult.data.data.email
        //       ? profileResult.data.data.email
        //       : "",
        //     name: `${profileResult.data.data.first_name} ${profileResult.data.data.last_name}`,
        //     about: "Khademan-User",
        //     phone: profileResult.data.data.mobile,
        //     avatar: "",
        //     tags: "",
        //     forceUpdate: true,
        //   });
        // }
      }

      /** Sign in */
      setUserAvatar(profileResult.data.data.avatar);

      toast({
        title: "وارد شدید",
        description: "در حال انتقال ...",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      /** Takes the user to the place he/she came from (if specified) or to the home page. */
      if (stayInPage) {
        setIsOpen();
      } else if (toParam) {
        navigate(toParam, { replace: true });
      } else {
        navigate("/", { replace: true });
      }

      signIn(token, sid);
    },
    onSuccess: () => {},
    onError: (e: any) => {
      console.log(e);
      if (e.response.data.message === "Invalid credentials!") {
        toast({
          title: "اطلاعات وارد شده صحیح نیست",
          description: "لطفا اطلاعات صحیح را جهت ورود وارد نمایید.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: "خطا در ورود",
          description:
            "متاسفانه در حین ورود خطایی رخ داد - با پشتیبانی تماس بگیرید",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  return (
    <ScaleFade initialScale={0.1} in>
      <Box textAlign="center" fontSize="md">
        <Grid p={5} mt={10}>
          <VStack spacing={4} justifySelf="center">
            <Button
              sx={{ direction: "ltr" }}
              onClick={() => {
                setSignInStep("phone");
              }}
              fontSize="sm"
              size="sm"
              leftIcon={<FaEdit />}
            >
              {userPhoneNumber}
            </Button>

            <FormControl isInvalid={userFName.trim().length === 0}>
              <FormLabel>نام</FormLabel>
              <Input
                type="text"
                value={userFName}
                onChange={(event) => setUserFName(event.target.value)}
              />
              <FormErrorMessage>نام الزامی است!</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={userLName.trim().length === 0}>
              <FormLabel>نام خانوادگی</FormLabel>
              <Input
                type="text"
                value={userLName}
                onChange={(event) => setUserLName(event.target.value)}
              />
              <FormErrorMessage>نام خانوادگی الزامی است!</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={userProvince.trim().length === 0}>
              <FormLabel>استان</FormLabel>

              <Select
                value={userProvince}
                placeholder="هیچکدام"
                onChange={(event) => {
                  setUserCity("");
                  setUserProvince(event.target.value);
                }}
              >
                {IranProvinces.map((p, index) => (
                  <option key={`ProvinceOption_${index}`} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>استان الزامی است!</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={userCity.trim().length === 0}>
              <FormLabel>شهر</FormLabel>

              <Select
                value={userCity}
                placeholder="هیچکدام"
                disabled={cityOptions.length === 0}
                onChange={(event) => setUserCity(event.target.value)}
              >
                {cityOptions.map((p, index) => (
                  <option key={`CityOption_${index}`} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </Select>

              <FormErrorMessage>شهر الزامی است!</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={password.trim().length === 0}>
              <FormLabel>رمز ورود به برنامه </FormLabel>
              <InputGroup size="md">
                <Input
                  value={password}
                  sx={{ direction: "ltr" }}
                  autoFocus
                  type={show ? "text" : "password"}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      checkPasswordMutation.mutate();
                    }
                  }}
                  pl={4}
                  pr={20}
                />
                <InputLeftElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "مخفی" : "نمایش"}
                  </Button>
                </InputLeftElement>
              </InputGroup>
            </FormControl>

            <Button
              fontSize="md"
              isLoading={checkPasswordMutation.isLoading}
              isDisabled={
                password.trim().length === 0 ||
                userFName.trim().length === 0 ||
                userLName.trim().length === 0 ||
                userProvince.trim().length === 0 ||
                userCity.trim().length === 0
              }
              loadingText="درحال ورود ..."
              colorScheme="blue"
              size="lg"
              minW="100%"
              mt={6}
              rightIcon={<FaArrowLeft />}
              onClick={() => {
                checkPasswordMutation.mutate();
              }}
            >
              تکمیل اطلاعات و ورود به برنامه
            </Button>
          </VStack>
        </Grid>
      </Box>
    </ScaleFade>
  );
}
