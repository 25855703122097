import { Link } from "react-router-dom";
import "./index.css";
import { Box, Button, useColorMode, Text, Skeleton } from "@chakra-ui/react";
import { BANNER_API_KEY } from "../../utils/keys";
import axios from "axios";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useAuth } from "hooks/useAuthContext";
import { useEffect, useState } from "react";
import {
  EventTopBarItems,
  DefaultTopBarItem,
  DefaultTopBarResult,
  EventTopBarResult,
} from "../../types/topBar";

const TopBar = () => {
  const [topBarData, setTopBarData] = useState<DefaultTopBarItem>();

  const location = useLocation();
  const colorMode = useColorMode();
  const { authorizedAxios } = useAuth();

  const getTopBarQuery = useQuery({
    queryKey: ["getBannerQuery", location.pathname],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    queryFn: async () => {
      const route = location.pathname.split("/");
      const eventId =
        route[route.length - 1].length > 10 ? route[route.length - 1] : "";
      const [defaultTopBar, eventTopBar]: [
        DefaultTopBarResult,
        EventTopBarResult
      ] = await Promise.allSettled([
        axios.get("https://nbe.shamim313.com/v1/api/banner/top_bar", {
          headers: {
            "x-api-key": BANNER_API_KEY,
            "Content-Type": "application/json",
          },
        }),
        authorizedAxios.get(`/event/${eventId}/top-bar/`),
      ]);
      const resBanner =
        defaultTopBar.status === "fulfilled"
          ? defaultTopBar.value.data?.data?.items
          : null;
      if (
        eventTopBar.status === "fulfilled" &&
        eventTopBar.value.data?.data?.event_id &&
        eventTopBar.value.data?.data?.is_active
      ) {
        const topBar: DefaultTopBarItem = {
          destinationType: eventTopBar.value.data?.data.destination_type,
          destination: eventTopBar.value.data?.data.destination,
          title: eventTopBar.value.data?.data.title,
          description: eventTopBar.value.data?.data.description,
          buttonText: eventTopBar.value.data?.data.button_text,
          buttonColor: eventTopBar.value.data?.data.button_color,
          background: eventTopBar.value.data?.data.background,
        } as DefaultTopBarItem;
        setTopBarData(topBar);
      } else if (defaultTopBar.status === "fulfilled") {
        setTopBarData(defaultTopBar.value.data?.data?.items[0]);
      }
      return resBanner;
    },
  });
  return (
    <div
      style={{
        backgroundImage: `${typeof topBarData?.background === "string" && topBarData?.background
          ? `url(${topBarData?.background})`
          : "url(/images/islamic-ornament-persian-01.jpg)"
          }`,
        width: "100%",
        backgroundSize: "cover",
        padding: "7px",
        position: "sticky",
        top: 0,
        zIndex: 9,
      }}
    >
      <Link
        to={topBarData?.destination || "#"}
        target={topBarData?.destinationType === "external" ? "_blank" : "_self"}
      >
        <Box
          style={{
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          bg={
            colorMode.colorMode === "light"
              ? "rgba(255, 255, 255, 0.9)"
              : "rgb(26 32 44 / 90%)"
          }
        >
          {getTopBarQuery.isLoading ? (
            <>
              <Skeleton w="100%" h="40px" borderRadius="6px" />
            </>
          ) : (
            <>
              <div>
                <strong>
                  <Text fontSize={{ base: "1rem", md: "1rem" }}>
                    {topBarData?.title || ""}
                  </Text>
                  <Text fontSize={{ base: "0.8rem", md: "0.8rem" }}>
                    {topBarData?.description || ""}
                  </Text>
                </strong>
              </div>
              <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="solid"
                  bg={topBarData?.buttonColor || "red"}
                  color="white"
                  width="100%"
                  padding={{ base: "0 4px", md: "0 50px" }}
                  className="topbar-button"
                >
                  {topBarData?.buttonText || ""}
                </Button>
              </div>
            </>
          )}
        </Box>
      </Link>
    </div>
  );
};

export default TopBar;
