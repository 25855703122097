import { useEffect, useState } from "react";
import { FaEdit, FaSms } from "react-icons/fa";
import { useMutation } from "react-query";
import convertPersianNumbersToEnglish from "utils/persianNumberToEnglish";
import { FaCheck } from "react-icons/fa6";
import axios from "axios";
import useIPC from "hooks/IPC";
import useIPS from "hooks/IPS";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuthContext";
import Cookies from "js-cookie";
import {
  Box,
  Flex,
  Text,
  VStack,
  Grid,
  Button,
  FormControl,
  HStack,
  PinInput,
  PinInputField,
  ScaleFade,
  useToast,
} from "@chakra-ui/react";

interface EnterPasswordProps {
  userPhoneNumber: string;
  setSignInStep: (step: string) => void;
  stayInPage?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  isSimpleView?: boolean;
}

const EnterOtp: React.FC<EnterPasswordProps> = ({
  userPhoneNumber,
  setSignInStep,
  stayInPage,
  setIsOpen,
  isSimpleView,
}) => {
  const { BASE_URL, signIn, setUserAvatar, makeUserVip } = useAuth();
  const [otp, setOtp] = useState("");
  const [timerValue, setTimerValue] = useState(180);
  const toast = useToast();
  const { setIPC } = useIPC();
  const { setIPS } = useIPS();
  const [searchParams] = useSearchParams();
  const toParam = searchParams.get("to");
  const navigate = useNavigate();

  /** Manages the countdown timer */
  useEffect(() => {
    if (timerValue <= 0) return;

    const timerInterval = setInterval(() => {
      setTimerValue((value) => value - 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timerValue]);

  /** Manages the sending of OTP verification request to the server */
  const checkOtpMutation = useMutation({
    mutationKey: ["checkOtpMutation"],
    retry: 0,
    // @ts-ignore
    mutationFn: async ({ currentOtp }) => {
      /** Sends a OTP verification request to the server */
      const res = await axios.post(`${BASE_URL}/user/login/`, {
        otp: currentOtp,
        password: "",
        mobile: userPhoneNumber.startsWith("0")
          ? "+98" + userPhoneNumber.slice(1)
          : "+98" + userPhoneNumber,
      });

      const sid = res.data.data.sid;
      const token = res.data.data.token;

      /** Get user profile information from the server */
      const profileResult = await axios.get(`${BASE_URL}/profile/?full=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Sid: sid,
        },
      });

      /** Checks whether the user has set password for his/her account or not */
      if (profileResult.data.data.has_password === true) {
        setIPS();
      }

      if (profileResult.data.data.vip === true) {
        makeUserVip();
      }

      /** Checks whether the user has completed his/her account information or not */
      if (
        // profileResult.data.data.national_code &&
        profileResult.data.data.first_name &&
        profileResult.data.data.last_name
        // profileResult.data.data.gender &&
        // profileResult.data.data.marital_status &&
        // profileResult.data.data.birth_date
      ) {
        /** The IPC flag is set in cookies */
        setIPC();

        /** Save user info in Cookies */
        Cookies.set(
          "UserProfileInfo",
          JSON.stringify({
            phone: profileResult.data.data.mobile,
            name: `${profileResult.data.data.first_name} ${profileResult.data.data.last_name}`,
            firstName: profileResult.data.data.first_name,
            lastName: profileResult.data.data.last_name,
            email: profileResult.data.data.email || "",
            gender: profileResult.data.data.gender || "",
            province: profileResult.data.data.province || "",
            city: profileResult.data.data.city || "",
          }),
          { expires: 365 }
        );

        /** Set Goftino user info if the Goftino service is available */
        // @ts-ignore
        if (window.GoftinoService) {
          // @ts-ignore
          window.GoftinoService.setUser({
            email: profileResult.data.data.email
              ? profileResult.data.data.email
              : "",
            name: `${profileResult.data.data.first_name} ${profileResult.data.data.last_name}`,
            firstName: profileResult.data.data.first_name,
            lastName: profileResult.data.data.last_name,
            about: "Khademan-User",
            phone: profileResult.data.data.mobile,
            avatar: "",
            tags: "",
            forceUpdate: true,
          });
        }
      }

      /** Sign in */
      if (!profileResult.data.data.has_password) {
        setSignInStep("profile");
      }

      signIn(token, sid);
      setUserAvatar(profileResult.data.data.avatar);
      if (profileResult.data.data.has_password) {
        toast({
          title: "وارد شدید",
          description: "در حال انتقال ...",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        /** Takes the user to the place he/she came from (if specified) or to the home page. */
        if (toParam) {
          navigate(toParam, { replace: true });
        } else if (stayInPage && setIsOpen) {
          setIsOpen(false);
        } else {
          navigate("/", { replace: true });
        }
      }

      return profileResult;
    },
    onSuccess: (profileResult) => {},
    onError: (e: any) => {
      if (e.response.data.message === "Invalid credentials!") {
        toast({
          title: "اطلاعات وارد شده صحیح نیست",
          description: "لطفا اطلاعات صحیح را جهت ورود وارد نمایید.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: "خطا در ورود",
          description:
            "متاسفانه در حین ورود خطایی رخ داد - با پشتیبانی تماس بگیرید",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  /** Manages the resending of the phone number verification code */
  const resendOtpMutation = useMutation({
    mutationKey: ["resendOtpMutation"],
    mutationFn: async () => {
      /** Sends a request to resend the phone number verification code */
      await axios.post(`${BASE_URL}/user/otp/`, {
        via: "mobile",
        value: userPhoneNumber.startsWith("0")
          ? "+98" + userPhoneNumber.slice(1)
          : "+98" + userPhoneNumber,
      });
    },
    onSuccess: () => {
      /** Resets the timer */
      setTimerValue(180);

      toast({
        title: "کد ارسال شد",
        description: "لطفا کد یکبار مصرف را وارد کنید",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (e: any) => {
      /** Checks whether the user recently received the phone number verification code or not */
      if (e.response) {
        if (e.response.data) {
          if (
            e.response.data.message.startsWith(
              "It seems that you recently made a request"
            )
          ) {
            /** Redirects the user to the EnterOtp section */
            setSignInStep("otp");

            toast({
              title: "کد قبلا ارسال شده",
              description:
                "شما به تازگی کدی دریافت نمودید، از همان استفاده کنید",
              status: "warning",
              duration: 4000,
              isClosable: true,
            });
          }
        }
      } else {
        toast({
          title: "خطایی رخ داده است",
          description: "لطفا اینترنت خود را بررسی کنید.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });

        // toast({
        //   title: `کد خطا: ${e.response.status}`,
        //   description: JSON.stringify(e.response.data, null, 2),
        //   status: "error",
        //   duration: 4000,
        //   isClosable: true,
        // });
      }
    },
  });

  return (
    <ScaleFade initialScale={0.1} in>
      <Box textAlign="center" fontSize="md">
        <Grid p={2}>
          <VStack spacing={4} justifySelf="center">
            {!isSimpleView ? (
              <>
                {userPhoneNumber.trim().length !== 0 ? (
                  <Button
                    sx={{ direction: "ltr" }}
                    onClick={() => {
                      setSignInStep("phone");
                    }}
                    fontSize="sm"
                    size="sm"
                    leftIcon={<FaEdit />}
                  >
                    {userPhoneNumber}
                  </Button>
                ) : null}
                {/* @ts-ignore */}
                <lord-icon
                  src="https://cdn.lordicon.com/yideukey.json"
                  trigger="loop"
                  style={{ width: "150px", height: "150px" }}
                >
                  {/* @ts-ignore */}
                </lord-icon>
              </>
            ) : (
              <></>
            )}
            {/* @ts-ignore */}
            <FormControl spacing={10}>
              {timerValue !== 0 ? (
                <Flex
                  flexWrap="nowrap"
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  fontSize=".9rem"
                  border="1px solid #ccc"
                  p="3px 5px"
                  width="max-content"
                  mx="auto"
                  borderRadius="100px"
                  mb="10px"
                >
                  {/* @ts-ignore */}
                  <lord-icon
                    src="https://cdn.lordicon.com/mwikjdwh.json"
                    trigger="loop"
                    delay="100"
                    style={{
                      width: "19px",
                      height: "19px",
                      marginLeft: "5px",
                    }}
                  >
                    {/* @ts-ignore */}
                  </lord-icon>
                  {timerValue} ثانیه تا انقضای کد
                </Flex>
              ) : null}

              <Text marginBottom={3} size="small">
                کد تأیید هم اکنون برایتان پیامک شد لطفا کد پیامک شده را در کادر
                زیر وارد کنید
              </Text>

              <HStack sx={{ direction: "ltr" }} justifyContent="center">
                <PinInput
                  autoFocus
                  size="lg"
                  otp
                  placeholder=""
                  value={otp}
                  onChange={(e) => {
                    setOtp(convertPersianNumbersToEnglish(e));
                  }}
                  onComplete={(e) => {
                    if (otp.length >= 3) {
                      // @ts-ignore
                      checkOtpMutation.mutate({ currentOtp: e });
                    }
                  }}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </FormControl>

            <Button
              onClick={() => {
                // @ts-ignore
                checkOtpMutation.mutate({ currentOtp: otp });
              }}
              isDisabled={otp.length < 4}
              fontSize="md"
              isLoading={checkOtpMutation.isLoading}
              loadingText="درحال ارسال ..."
              colorScheme="blue"
              size="lg"
              minW="100%"
              leftIcon={<FaCheck />}
            >
              تایید کد
            </Button>

            {timerValue === 0 ? (
              <Button
                onClick={() => {
                  resendOtpMutation.mutate();
                }}
                fontSize="md"
                isLoading={resendOtpMutation.isLoading}
                loadingText="درحال ارسال دوباره ..."
                variant="outline"
                size="lg"
                minW="100%"
                leftIcon={<FaSms />}
              >
                ارسال مجدد کد
              </Button>
            ) : null}
          </VStack>
        </Grid>
      </Box>
    </ScaleFade>
  );
};

export default EnterOtp;
