import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Textarea,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "hooks/useAuthContext";

export default function CommentInfoDrawer({
  eventId,
  comment = undefined,
  isOpen = false,
  onClose = () => {},
  onCloseComplete = () => {},
}) {
  const { BASE_URL, token, sid, checkForInvalidToken } = useAuth();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [commentText, setCommentText] = useState(comment ? comment.text : "");
  const [showMyName, setShowMyNameState] = useState(
    comment ? typeof comment.name === "string" : true
  );
  const drawerPlacement = useBreakpointValue(
    {
      base: "bottom",
      lg: "right",
    },
    { fallback: "base" }
  );

  const saveCommentMutation = useMutation({
    mutationKey: ["saveCommentMutation"],
    retry: 0,
    mutationFn: async () => {
      if (comment) {
        await axios.put(
          `${BASE_URL}/comment/${comment.id}/`,
          {
            text: commentText,
            show_name: showMyName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              sid: sid,
            },
          }
        );
      } else {
        await axios.post(
          `${BASE_URL}/comment/`,
          {
            event_id: eventId,
            text: commentText,
            show_name: showMyName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              sid: sid,
            },
          }
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getCommentsInfiniteQuery"]);
      onClose();

      toast({
        title: `نظر شما با موفقیت ${comment ? "به روز" : "ایجاد"} شد`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (e) => {
      if (checkForInvalidToken(e)) return;

      toast({
        title: `متاسفانه نظر شما ${comment ? "به روز" : "ایجاد"} نشد!`,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  return (
    <Drawer
      size="lg"
      placement={drawerPlacement}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        onCloseComplete();
        if (!comment) {
          setCommentText("");
          setShowMyNameState(true);
        } else {
          setCommentText(comment.text);
          setShowMyNameState(typeof comment.name === "string");
        }
      }}
      closeOnEsc={!saveCommentMutation.isLoading}
      closeOnOverlayClick={!saveCommentMutation.isLoading}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{comment ? "ویرایش نظر" : "ثبت نظر جدید"}</DrawerHeader>

        <DrawerBody>
          <FormControl isInvalid={commentText.trim().length === 0}>
            <FormLabel>متن نظر</FormLabel>

            <Textarea
              rows={7}
              maxLength={666}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />

            <FormErrorMessage>پر کردن این فیلد الزامی است!</FormErrorMessage>

            {commentText.trim().length > 0 ? (
              <FormHelperText textAlign="end">{`666/${commentText.length}`}</FormHelperText>
            ) : null}
          </FormControl>

          <Box w="100%" mt="1.5rem">
            <Box
              cursor="pointer"
              onClick={() => setShowMyNameState((pv) => !pv)}
            >
              <Checkbox pointerEvents="none" isChecked={showMyName}>
                نام من را به همه نمایش بده!
              </Checkbox>
            </Box>
          </Box>
        </DrawerBody>

        <DrawerFooter as={HStack} alignItems="center" gap=".75rem">
          <Button
            flex="1"
            variant="outline"
            isDisabled={saveCommentMutation.isLoading}
            onClick={onClose}
          >
            منصرف شدم
          </Button>

          <Button
            flex="1"
            colorScheme="telegram"
            isDisabled={commentText.trim().length === 0}
            isLoading={saveCommentMutation.isLoading}
            onClick={() => saveCommentMutation.mutate()}
          >
            ثبت نظر
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
