export default function calculateEventStartTime(event, currentDate) {
  const eventStartDate = new Date(event.start_date);
  const dailyStartTime = { hours: 0, minutes: 0 };

  if (event.daily_start_time) {
    const dailyStartTimeParts = event.daily_start_time.split(":");
    dailyStartTime.hours = parseInt(dailyStartTimeParts[0] || "0");
    dailyStartTime.minutes = parseInt(dailyStartTimeParts[1] || "0");
  }

  eventStartDate.setHours(dailyStartTime.hours);
  eventStartDate.setMinutes(dailyStartTime.minutes);

  // تبدیل currentDate به ساعت جهانی (UTC)
  const currentDateUTC = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000));

  return eventStartDate - currentDateUTC;
}